<template>
  <div class="announcement-wrapper">
    <PageTitle
      title="重要公告"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.go(-1)"
    />
    <!-- <HomeBtn /> -->

    <div v-loading="loading" class="announcement-list">
      <router-link v-for="(row, index) in tableData" :key="row.id" :to="{name: 'AnnouncementDetail', params: {id: row.id} }">
        <div class="announcement-item">
          <div class="flex items-center justify-between" style="gap: 12px">
            <p class="item-title truncate">
              <img v-if="row.isTop" src="../assets/pin.svg">
              <span class="truncate">{{ row.title }}</span>
            </p>
            <p class="time">{{ dateFormat(row.publishedAt) }}</p>
          </div>
          <el-divider v-if="index !== tableData.length - 1" class="item-divider" />
        </div>
      </router-link>
    </div>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableOptions.dataCount"
      @pageChange="refresh"
    />
  </div>
</template>

<script>
// import HomeBtn from './HomeBtn.vue'
import { GetAnnouncement, GetAnnouncementCount } from '@/api/homePage'
import tableMixin from '@/mixin/table'

export default {
  name: 'AnnouncementList',
  // components: { HomeBtn },
  mixins: [tableMixin],
  data: () => ({
    tableData: [],
  }),
  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      this.loading = true
      await this.getTableDataCount()
      await this.getTableData()
      this.loading = false
    },
    async getTableDataCount () {
      const [res, err] = await GetAnnouncementCount({})
      if (err) {
        this.$message.error(err.msg || err)
        return
      }
      this.tableOptions.dataCount = res.count
    },
    async getTableData () {
      const [res, err] = await GetAnnouncement({
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
      })
      if (err) {
        this.$message.error(err.msg || err)
        return
      }
      this.tableData = res
    },
  },
}
</script>

<style lang="scss" scoped>
.announcement-wrapper {
  @apply relative;
}
.announcement-list {
  @apply bg-white rounded-[10px] p-[20px];
  @apply flex flex-col gap-[16px];
  box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);
}

.announcement-item {
  // @apply flex justify-between items-center p-[20px];

  .time {
    @apply text-gray-60 text-sm flex-shrink-0;
  }
}

.item-divider {
  @apply mt-[20px] mb-0;
}

.item-title {
  @apply text-gray-100 flex items-center gap-[12px];
}
</style>
